<template>
  <div class="index">
    <Header url="regist"></Header>
    <div class="loginBg">
      <div class="loginbox">

        <div class="ltitle">注册账号</div>

        <div class="count">

          <div class="litem"><span class="spanleft">用户名</span><input class="input" placeholder="" type="text" /></div>
          <div class="litem"><span class="spanleft">邮  箱</span><input class="input" placeholder="" type="text" /></div>
          <div class="litem"><span class="spanleft">密  码</span><input class="input" placeholder="" type="text" /></div>
          <div class="litem"><span class="spanleft">  </span>
             <input class="checkbox" type="checkbox" id="checkbox" :checked="check" @input="keyInputFu" v-model="check"/>
            <label for="checkbox">自动登录</label>
          </div>
          <div class="litem">
            <span class="spanleft">   </span>
            <div class="loginbtn bgcolor">确定</div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<style>
.loginBg{    background: url(../assets/img/loginBg.jpg) center top no-repeat;
  margin-top: 75px;padding-top: 30px;
  height: 686px;
  color: #555555;}
.loginbox{
  position: relative;
  width: 500px;float:right;
  height: 500px;
  background-color: #ffffff;
  margin: 57px 80px;
  font-family: "microsoft yahei";
  border-radius: 8px;
  box-shadow: -26px 25px 8px rgb(0 0 0 / 5%);}
.ltitle{text-align:center;font-size:20px;padding:70px 0 50px;}
.litem{padding:10px 30px;}
.litem .spanleft{    width: 55px;    display: inline-block;}
.litem .input{    width: 379px;    height: 40px;    border-radius: 4px;    border: 1px solid #ccc;    outline: 0;    text-indent: 10px;}
.litem .checkbox{margin: 0 3px;    vertical-align: middle;}
.litem  .gotpwd{cursor: pointer;font-size: 14px;padding-right:6px}
.loginbtn{width: 384px;margin:20px 0;
  height: 50px;
  font-size: 20px;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  background-color: #1EBCF0;
  cursor: pointer;
  border-radius: 5px;    display: inline-block;
  line-height: 50px;}
</style>
<script>
import Header from '@/components/header'
import Footer from '@/components/footer'


export default {
  name: 'Login',
  components:{Header,Footer},
  data() {
    return {
      check:true
    }
  },
  mounted(){},
  methods: {
    keyInputFu(){
      this.check = !this.check
    }
  }
}
</script>
